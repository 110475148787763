<template>
  <div class="comm-job-container center column">

    <div class="comm-notice">
      <div clsas="comm-job-title">
        <p class="comm-job-title-text">취업현황</p>
      </div>


    <hr>
    <table class="comm-job-table">
      <tr  class="comm-job-table-header">
        <th class="comm-job-table-number">NO</th>
        <th class="comm-job-table-company">취업처</th>
        <th class="comm-job-table-name">취업자</th>
        <th class="comm-job-table-date">취업일</th>
        <th class="comm-job-table-type">근무직종</th>
        <th class="comm-job-table-course">과정명</th>
      </tr>

      <tr class="comm-job-table-content cursor"
      v-for="(item, index) in JOB_LIST"
      :key="'comm-job-table'+index">
        <td class="comm-job-table-number">{{(JOB_LIST_TOTAL) - index - ((pageIndex-1)*10)}}</td>
        <td class="comm-job-table-company">{{item.company}}</td>
        <td class="comm-job-table-name">{{item.name}}</td>
        <td class="comm-job-table-date">{{changeDateFormat(item.date)}}</td>
        <td class="comm-job-table-type">{{item.type}}</td>
        <td class="comm-job-table-course">{{item.course}}</td>
      </tr>
    </table>

    <div class="comm-job-pagination-container">
      <button class="comm-job-pagination-btn cursor"
      @click="onPrevious()">
        <img class="comm-job-pagination-image" :src="paginationBtnLeftIcon"/>
      </button>
      <div class="comm-job-pagination">
        <span class="comm-job-pagination-current">{{pageIndex}}</span>
        /
        <span class="comm-job-pagination-total">{{totalIndex}}</span>
      </div>
      <button class="comm-job-pagination-btn cursor"
      @click="onNext()">
        <img class="comm-job-pagination-image" :src="paginationBtnRightIcon"/>
      </button>
    </div>

    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import paginationBtnLeftIcon from '../../assets/icons/pagination_btn_left_icon.svg';
import paginationBtnRightIcon from '../../assets/icons/pagination_btn_right_icon.svg';

import moment from 'moment';

export default {
  data() {
    return {
      pageIndex : 1,
      paginationBtnLeftIcon,
      paginationBtnRightIcon,
    }
  },

  created() {
    this.FETCH_JOB_LIST(this.pageIndex)
  },

  methods : {
    ...mapActions([
      'FETCH_JOB_LIST'
    ]),

    onPrevious() {
      if(this.pageIndex == 1) return
      this.pageIndex -= 1;
      this.FETCH_JOB_LIST(this.pageIndex)
    },

    onNext() {
      if(this.pageIndex == this.totalIndex) return
      this.pageIndex += 1;
      console.log(this.pageIndex)
      this.FETCH_JOB_LIST(this.pageIndex)
    },

    changeDateFormat(date) {
        const d = new Date(date)
        return moment(d).format('YY-MM-DD')
    },

  },

  computed : {
    ...mapState([
      'JOB_LIST', 'JOB_LIST_TOTAL'
    ]),

    totalIndex() {
      const count = this.JOB_LIST_TOTAL;
      return parseInt(count/10) + (parseInt(count%10)>0? 1:0);
    }
  }
}
</script>

<style>

</style>
